import {
	CarrierQuestion as CarrierQuestionProto,
	CarrierStep as CarrierStepProto
} from '@agentero/grpc-clients/grpc/shared/form';

import { Carrier, getCarrierFromProto } from '../../../shared';
import { CarrierApplication, CarrierApplicationStatus } from '../CarrierApplication';
import { RiskState } from '../RaterSchema';
import { parseList, parseListArgs } from '../shared/parseList';
import { getDefaultValuesFromBasicStep } from './BasicStep';
import { IStep, StepType } from './Step';
import {
	Section,
	SectionState,
	getSectionList,
	getSectionsDestinations,
	getSectionsStateFromDestinations,
	parseSection
} from './step/Section';
import { hasFieldDefaultValue } from './step/section/Field';
import { FieldDestination } from './step/section/field/FieldDestination';

export type CarrierStep = IStep & {
	type: StepType.CarrierQuestions;
	carrierQuestions: CarrierQuestions[];
};

export type CarrierQuestions = {
	carrier: Carrier;
	sections: Section[];
};

export type CarrierStepState = {
	[key in Carrier]?: CarrierState;
};
export type CarrierState = SectionState;

export const parseCarrierStep = (step: CarrierStepProto.AsObject): CarrierStep => ({
	...step,
	carrierQuestions: parseList(
		'carrierQuestionsList in CarrierStepProto.AsObject',
		parseCarriersQuestions,
		step.carrierQuestionsList
	),
	type: StepType.CarrierQuestions
});

const parseCarriersQuestions = (
	carrierQuestions: CarrierQuestionProto.AsObject
): CarrierQuestions => ({
	carrier: getCarrierFromProto(carrierQuestions.carrier),
	sections: parseListArgs(
		'sectionsList in CarrierQuestionProto.AsObject',
		parseSection,
		getSectionList(carrierQuestions.sectionsList, carrierQuestions.formSectionsList)
	)
});

export const hasCarrierQuestionDefaultValues = (carrierQuestion: CarrierQuestions) => {
	return carrierQuestion.sections.every(section =>
		section.fields.every(field => hasFieldDefaultValue(field))
	);
};

export const getCarriersStepDestinations = (
	step: CarrierStep,
	values?: CarrierStepState
): FieldDestination => {
	return step.carrierQuestions.reduce((dictionary, carrierQuestion) => {
		const sectionFields = getSectionsDestinations(
			carrierQuestion.sections,
			values && values[carrierQuestion.carrier] ? values[carrierQuestion.carrier] : {}
		);
		return { ...dictionary, ...sectionFields };
	}, {} as FieldDestination);
};

export const getCarrierStepStateFromDestinations = (
	step: CarrierStep,
	values: Record<string, string>
) => {
	const carriersState = step.carrierQuestions.reduce((acc, carrierQuestion) => {
		const sectionsValues = getSectionsStateFromDestinations(carrierQuestion.sections, values);

		if (Object.keys(sectionsValues).length === 0) return acc;

		return { ...acc, [carrierQuestion.carrier]: sectionsValues };
	}, {});

	return Object.keys(carriersState).length > 0 ? carriersState : undefined;
};

export const isCarrierStepQuestionRequired = (
	carrierQuestions: CarrierQuestions[],
	applications: CarrierApplication[]
) => {
	return applications
		.filter(application => application.status !== CarrierApplicationStatus.NotAvailable)
		.every(carrierApplication =>
			carrierQuestions
				.filter(carrierQuestion => !hasCarrierQuestionDefaultValues(carrierQuestion))
				.some(carrierQuestion => carrierQuestion.carrier === carrierApplication.carrier)
		);
};

export const getCarrierQuestionsDefaultValues = (
	step: CarrierStep,
	carrier: Carrier,
	risk: RiskState
) => {
	return (
		(risk[step.id] as CarrierStepState)?.[carrier] ??
		getDefaultValuesFromBasicStep(
			step.carrierQuestions.find(questions => questions.carrier === carrier) as CarrierQuestions,
			risk
		)
	);
};
