import { FC } from 'react';

import { useRouter } from 'next/router';

import { News } from '@agentero/models/news';
import { Carrier, getSlideshowConfiguration } from '@agentero/models/shared';
import { SlideshowItem } from '@agentero/ui';

import { slideshowCtaClick } from 'packages/analytics/events/slideshow';
import { useAgAnalytics } from 'packages/analytics/useAgAnalytics';
import { useCarrierNewsAgencyQuery } from 'packages/services/fetch/news-fe/useCarrierNewsAgency/useCarrierNewsAgency';
import { getCarrierDetailsPath } from 'routes';

export const convertCarrierNewsToSlideshowItems = (
	news: News[],
	onCarrierAction: (carrier: Carrier) => void
): SlideshowItem[] => {
	return news.map(newsItem => {
		const slideshowConfig = getSlideshowConfiguration(newsItem.carrier);

		const Icon = slideshowConfig.icon as FC;

		return {
			id: newsItem.id,
			title: newsItem.title,
			body: newsItem.content,
			image: <Icon />,
			cta: {
				label: newsItem.label,
				action: () => onCarrierAction(newsItem.carrier)
			},
			color: slideshowConfig.color
		};
	});
};

export const useDashboardSlideshow = () => {
	const { trackEvent } = useAgAnalytics();
	const { push } = useRouter();

	const { data } = useCarrierNewsAgencyQuery();

	const onCarrierAction = (carrier: Carrier) => {
		trackEvent(slideshowCtaClick(carrier));
		push(getCarrierDetailsPath(carrier));
	};

	return {
		items: convertCarrierNewsToSlideshowItems(data, onCarrierAction)
	};
};
