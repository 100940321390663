import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { ORDER_DIRECTION } from '@agentero/components';
import { IconAppointments, IconArrowForward, PictogramUnlock } from '@agentero/icons';
import { AppointmentRequestStatus, CarriersOrderByField } from '@agentero/models';
import { EOStatus } from '@agentero/models/agency';
import { getCarrierLabel, getCarrierLogoSrc } from '@agentero/models/shared';
import { css } from '@agentero/styles/css';
import { Grid, HStack, Stack, VStack, styled } from '@agentero/styles/jsx';
import { Avatar, Card, CardActionArea, Text } from '@agentero/ui';

import { enterInAppointmentWidget } from 'packages/analytics/events/dashboard';
import { useAgAnalytics } from 'packages/analytics/useAgAnalytics';
import { useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { useCarrierListQuery } from 'packages/services/fetch/carrier-fe/carrier-list/useCarrierListQuery';
import { carriersRoute } from 'routes';

import { CarriersAppointmentWidgetModal } from './carriersAppointmentWidget/CarriersAppointmentWidgetModal';
import { useCarrierInQueryParam } from './shared/useCarrierQueryParam';

const CarrierLogo = styled('img', {
	base: {
		maxHeight: '2.5rem',
		objectFit: 'contain',
		objectPosition: 'left center',
		margin: '1.5rem'
	}
});

export const CarriersAppointmentWidget = () => {
	const { t } = useTranslation(['dashboard', 'shared']);
	const {
		data: {
			appointmentsEnabled,
			compliance: { bwaQualified, eoStatus }
		}
	} = useAgentResource();

	const { trackEvent } = useAgAnalytics();

	const isWidgetVisible = bwaQualified && eoStatus === EOStatus.Approved;

	const { selectedCarrier, selectCarrier, removeCarrierSelected } =
		useCarrierInQueryParam('appointed_carrier');

	const { data: carrierList } = useCarrierListQuery({
		search: {
			page: 1,
			filters: {
				searchTerm: '',
				appointmentStatus: AppointmentRequestStatus.NotRequested
			},
			order: {
				direction: ORDER_DIRECTION.DESC,
				field: CarriersOrderByField.Score
			}
		}
	});

	return isWidgetVisible && (!appointmentsEnabled || carrierList.length > 0) ? (
		<>
			<Stack gap="24" onMouseEnter={() => trackEvent(enterInAppointmentWidget(carrierList.length))}>
				<Text size="title.subsection" id="carrier-path-title">
					<b>{t('carrierAppointed.title')}</b>
				</Text>
				<Card className={css({ overflow: 'hidden' })}>
					{!appointmentsEnabled ? (
						<Link href={carriersRoute} passHref legacyBehavior>
							<CardActionArea as="a">
								<VStack justifyContent="start" alignItems="start" gap="24">
									<VStack justifyContent="start" alignItems="start" gap="8" maxWidth="19.875rem">
										<PictogramUnlock />
										<Text size="title.section">
											<b>{t('carrierAppointed.descriptionNoEnabledAppointments')}</b>
										</Text>
									</VStack>
									<div>
										<IconArrowForward className={css({ width: '2rem', height: '2rem' })} />
									</div>
									<Image
										src="/portal-ui-content/assets/images/carriers/carrier_constelation.png"
										width={512}
										height={512}
										alt="Carrier constelation"
										className={css({ position: 'absolute', right: 0, top: '-50%' })}
									/>
								</VStack>
							</CardActionArea>
						</Link>
					) : (
						<Grid
							gridTemplateColumns={{
								md: '0.5fr 1fr',
								base: '1fr'
							}}>
							<VStack justifyContent="center" alignItems="start" gap="24">
								<Avatar fallback={<IconAppointments />} size="lg" />
								<Text size="body" id="carrier-appointed-title">
									<b>{t('carrierAppointed.description')}</b>
								</Text>
							</VStack>
							<Stack gap="0">
								{carrierList.slice(0, 3).map((carrierItem, index) => (
									<HStack
										justifyContent="space-between"
										key={carrierItem.carrier}
										paddingRight="24"
										borderBottom={index < 2 ? 'solid 1px' : undefined}
										borderColor={index < 2 ? 'neutral.90' : undefined}
										onClick={() => selectCarrier(carrierItem.carrier)}
										className={css({
											_hover: {
												cursor: 'pointer',
												backgroundColor: 'neutral.95'
											}
										})}>
										<CarrierLogo
											title={getCarrierLabel(carrierItem.carrier)}
											src={getCarrierLogoSrc(carrierItem.carrier)}
										/>

										<IconArrowForward />
									</HStack>
								))}
							</Stack>
						</Grid>
					)}
				</Card>
			</Stack>
			{!!selectedCarrier && (
				<CarriersAppointmentWidgetModal onClose={removeCarrierSelected} carrier={selectedCarrier} />
			)}
		</>
	) : null;
};
