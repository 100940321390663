import { ComponentType, useEffect } from 'react';

import { dehydrate } from '@tanstack/react-query';
import { NextPage, NextPageContext } from 'next';
import { BaseContext } from 'next/dist/shared/lib/utils';
import { setCookie } from 'nookies';
import { v4 as uuidv4 } from 'uuid';

import { Agent, AppointmentRequestStatus, isSuperAdmin } from '@agentero/models';
import { FeatureFlagKey } from '@agentero/models/featureflags';
import { getCarrierLabel } from '@agentero/models/shared';
import { Tier } from '@agentero/models/tier';
import { RequestError } from '@agentero/service-errors';

import { GetInitialPropsErrorProvider } from 'packages/contexts/GetInitialPropsErrorContext';
import { RouteGuard } from 'packages/guards/routeGuard';
import { queryClient } from 'packages/services/QueryCache';
import { prefetchAgent, useAgentResource } from 'packages/services/fetch/back-ag/useAgentResource';
import { fetchCarrierListQuery } from 'packages/services/fetch/carrier-fe/carrier-list/useCarrierListQuery';
import {
	Flags,
	fetchFeatureFlagsQuery
} from 'packages/services/fetch/feature-flags-fe/useFeatureFlagsQuery';
import { accountVerificationRoute, paymentRoute } from 'routes';

import { getSkipVerificationCookie } from './cookies';
import { getIsomorphicPathname } from './getIsomorphicPathname';
import { LogLevel, addLog } from './logger';
import {
	handleNotAuthorizeRequestError,
	isomorphicRedirect
} from './withAgentAuth/getInitialPropsActions';
import { isComplianceWizardRequired } from './withAgentAuth/isRedirectToOnboardingWizard';

declare const FS: {
	identify: (
		id: string,
		user: {
			displayName: string;
			email: string;
			isImpersonation: boolean;
			tier?: Tier;
			agencyId: string;
			isTierActive: boolean;
			hasFirstPolicySold: boolean;
			eligibleCarriers: string[];
			appointedCarriers: string[];
			appointedInProgressCarriers: string[];
		}
	) => void;
};

type WithAgentAuthProps<T> = {
	pageProps?: T;
	error?: RequestError;
	agentUniqueIdentifier: string;
};

export type AgenteroPageComponentType<
	C extends BaseContext = NextPageContext,
	IP = Record<never, never>,
	P = Record<never, never>
> = ComponentType<P> & {
	getInitialProps?(context: C, agent: Agent, flags: Flags): IP | Promise<IP>;
};

export type AgenteroAuthPage<P = Record<never, never>, IP = P> = AgenteroPageComponentType<
	NextPageContext,
	IP,
	P
>;

export const withAgentAuth = <T extends Record<string, unknown>>(
	Component: AgenteroAuthPage<T>,
	routeGuard?: RouteGuard
) => {
	const WithAgentAuth: NextPage<WithAgentAuthProps<T>> = ({ pageProps, error }) => {
		const { data } = useAgentResource();

		useEffect(() => {
			const initializeFullStory = async () => {
				if (
					process.env.NODE_ENV === 'production' &&
					!data.isImpersonation &&
					!isSuperAdmin(data.role)
				) {
					const carrierList = await fetchCarrierListQuery(
						{ token: data.token, userId: data.id },
						{
							search: {
								page: 1,
								filters: {
									searchTerm: ''
								}
							}
						}
					);

					const { firstName, lastName, email, id, subscription, agency } = data;
					window.hasOwnProperty('FS') &&
						FS.identify(id, {
							displayName: `${firstName} ${lastName}`,
							email,
							isImpersonation: data.isImpersonation,
							tier: subscription?.tier,
							agencyId: agency.id,
							isTierActive: subscription?.isActive,
							hasFirstPolicySold: subscription?.hasFirstPolicySold,
							eligibleCarriers: carrierList
								?.filter(
									carrier =>
										carrier.appointmentRequestStatus === AppointmentRequestStatus.NotRequested
								)
								.map(carrier => getCarrierLabel(carrier.carrier)),
							appointedCarriers: carrierList
								?.filter(
									carrier => carrier.appointmentRequestStatus === AppointmentRequestStatus.Approved
								)
								.map(carrier => getCarrierLabel(carrier.carrier)),
							appointedInProgressCarriers: carrierList
								?.filter(
									carrier =>
										carrier.appointmentRequestStatus === AppointmentRequestStatus.InProgress
								)
								.map(carrier => getCarrierLabel(carrier.carrier))
						});
				}
			};

			initializeFullStory();
		}, []);

		return (
			<GetInitialPropsErrorProvider error={error}>
				<Component {...pageProps} />
			</GetInitialPropsErrorProvider>
		);
	};

	WithAgentAuth.getInitialProps = async ctx => {
		let agentUniqueIdentifier;

		//This is to have individual cache key in @tanstack/react-query in server when fetching current_expert as it has
		//no other way to identify the request as current_expert is the same for all users.
		//This way the cache will be updated as the key changes due to the passed agentUniqueIdentifier
		if (typeof window !== 'undefined') {
			agentUniqueIdentifier = document
				.getElementById('agent-identifier')
				.getAttribute('data-identifier');
		} else {
			agentUniqueIdentifier = uuidv4();
		}

		let agent: Agent;
		let flags: Flags;

		try {
			agent = await prefetchAgent(
				{
					headers: {
						Cookie: ctx.req?.headers?.cookie
					}
				},
				agentUniqueIdentifier
			);
		} catch (error) {
			addLog(
				{
					message: 'Unauthenticated agent on withAgentAuth',
					pageRoute: getIsomorphicPathname(ctx),
					...error,
					userId: ctx.req?.headers?.cookie
				},
				LogLevel.Info
			);
			handleNotAuthorizeRequestError(error, ctx);
			return { error, agentUniqueIdentifier, dehydratedState: dehydrate(queryClient) };
		}

		try {
			flags = await fetchFeatureFlagsQuery({
				agencyId: agent.agency.id,
				userId: agent.id,
				token: agent.token
			});
		} catch (error) {
			handleNotAuthorizeRequestError(error, ctx);
			addLog(
				{
					message: 'Error prefetching feature flags',
					userId: agent.id,
					agencyId: agent.agency.id,
					pageRoute: getIsomorphicPathname(ctx),
					...error
				},
				LogLevel.Warn
			);
			return { error, agentUniqueIdentifier, dehydratedState: dehydrate(queryClient) };
		}

		const isSkipVerificationCookie = getSkipVerificationCookie(ctx);
		const isWizardFlagEnabled = flags[FeatureFlagKey.ComplianceWizard];

		const isContractAccepted = !agent.isContractAccepted && !agent.isImpersonation;

		if (
			getIsomorphicPathname(ctx) !== accountVerificationRoute &&
			!agent.subscription?.isBlocked &&
			(isComplianceWizardRequired({
				agent,
				isWizardFlagEnabled,
				isSkipVerificationCookie
			}) ||
				isContractAccepted)
		) {
			const redirectUrl = ctx?.req?.url || window.location.pathname;
			setCookie(ctx, 'redirectUrl', redirectUrl, {
				path: '/',
				httpOnly: false
			});
			isomorphicRedirect(ctx, accountVerificationRoute);
			return {
				error: '',
				agentUniqueIdentifier,
				dehydratedState: dehydrate(queryClient)
			};
		}

		if (agent.subscription?.isBlocked && getIsomorphicPathname(ctx) !== paymentRoute) {
			isomorphicRedirect(ctx, paymentRoute);
			return {
				error: '',
				agentUniqueIdentifier,
				dehydratedState: dehydrate(queryClient)
			};
		}

		try {
			if (routeGuard) {
				const isValid = await routeGuard(agent, ctx, flags);

				if (!isValid) {
					const error = { messageError: 'Client guard unauthorized', statusError: 403 };
					addLog(
						{
							message: `Agent tries to enter to page ${getIsomorphicPathname(
								ctx
							)} but he has not access (client-guard)`,
							actionDispatched: 'Show page with not allowed information',
							pageRoute: getIsomorphicPathname(ctx),
							userId: agent.id,
							...error
						},
						LogLevel.Info
					);

					return {
						error,
						agentUniqueIdentifier,
						dehydratedState: dehydrate(queryClient)
					};
				}
			}
		} catch (error) {
			addLog({
				message: `Agent tries to enter to page ${getIsomorphicPathname(
					ctx
				)} but the guard request has failed`,
				actionDispatched: 'Show page with not allowed information',
				pageRoute: getIsomorphicPathname(ctx),
				...error,
				userId: agent.id
			});
			const guardInvalidError = { statusError: 403 };
			return {
				error: guardInvalidError,
				agentUniqueIdentifier,
				dehydratedState: dehydrate(queryClient)
			};
		}

		try {
			const pageProps =
				Component.getInitialProps && (await Component.getInitialProps(ctx, agent, flags));
			return {
				pageProps,
				agentUniqueIdentifier,
				dehydratedState: dehydrate(queryClient),
				agent
			};
		} catch (error) {
			addLog(
				{
					message: `Page getInitialProps error`,
					actionDispatched: 'Show page with error',
					pageRoute: getIsomorphicPathname(ctx),
					...error
				},
				LogLevel.Error,
				false
			);
			return { error, agentUniqueIdentifier, dehydratedState: dehydrate(queryClient) };
		}
	};

	return WithAgentAuth;
};
